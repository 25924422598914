import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import { Container, Row, Col } from 'react-grid-system';

import Hero from '../components/rows/hero/index';
import Banner from '../images/hero-images/how-to-book-hero.jpg';
import OneCol from '../components/rows/onecol';
import Follow from '../components/rows/cta/follow';
import Accordion from '../components/elements/accordion';

export default function Booking({ data }) {
  return (
    <Layout>
      <Seo
        title="How to Book"
        description="We can’t wait to see you! Find out how to book your in-person or virtual appointment with PACE."
      />
      <Hero title="How to Book" banner={Banner} />
      {data.allStrapiBooking.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <Container className="acc-wrap mb-56">
            <Row>
              <Col md={6} sm={12} className="mx-auto">
                <h2 className="text-center">
                  <span className="text-red-300">How to book your </span>{' '}
                  cardiology consultation or test
                </h2>
              </Col>
            </Row>
            <Accordion data={node.book_acc} />
          </Container>
        </React.Fragment>
      ))}
      <OneCol
        blueBG
        pdf
        title="Visit your Family Physician, Nurse Practitioner or a walk-in clinic."
        link="Patient Referral Form"
        href="https://afcompanion.com/PacePatientReferralForm.html"
        target="_blank"
        link2="No Family Doctor"
        href2="https://afcompanion.com/pacecardiologynewpatientform.html"
        rel="noreferrer"
      />
      <Follow />
    </Layout>
  );
}

export const query = graphql`
  query Booking {
    allStrapiBooking {
      edges {
        node {
          id # id of the node
          book_acc {
            id
            title
            description
          }
        }
      }
    }
  }
`;
